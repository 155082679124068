import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Page not found!</h1>
  </Layout>
)

export default NotFoundPage
